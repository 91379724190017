randomNavBackground();

window.onscroll = function() {navShrink()};

var navTrigger = document.getElementById('global-nav-trigger');
navTrigger.addEventListener('click', function(e){
  navHandler();
  e.preventDefault();
});

var headerTrigger = document.querySelector('.global-header__text a');
headerTrigger.addEventListener('click', function(e){
  navHandler();
  e.preventDefault();
});

// Form processing with recaptcha and messaging
var contactForm = document.getElementById('contactForm');

if(contactForm){
  contactForm.addEventListener('submit', function (event) {
    event.preventDefault();
    grecaptcha.reset();
    grecaptcha.execute();
  });
}

let documentUrl = window.location.href;

if(documentUrl.includes('/blog/')){
  if(document.querySelector('.post')){
    let copyArea = document.querySelector('.post').innerHTML;
    if(copyArea.includes('[1]')){
      window.onload = footnote(1);
      if(copyArea.includes('[2]')){
        window.onload = footnote(2);
        if(copyArea.includes('[3]')){
          window.onload = footnote(3);
          if(copyArea.includes('[4]')){
            window.onload = footnote(4);
            if(copyArea.includes('[5]')){
              window.onload = footnote(5);
              if(copyArea.includes('[6]')){
                window.onload = footnote(6);
                if(copyArea.includes('[7]')){
                  window.onload = footnote(7);
                  if(copyArea.includes('[8]')){
                    window.onload = footnote(8);
                    if(copyArea.includes('[9]')){
                      window.onload = footnote(9);
                      if(copyArea.includes('[10]')){
                        window.onload = footnote(10);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  function footnote(num) {
    document.querySelector('.post').innerHTML = document.querySelector('.post').innerHTML.replace(`[${num}]`, `<a href="#footnote${num}" class="footnote-link" id="footnoteNum${num}"><sup>[${num}]</sup></a>`);
  }
}

function formSubmit(response) {
  document.getElementById('contactForm').submit();
}
// End: Form processing with recaptcha and messaging

document.querySelectorAll('a[href^="#"]').forEach(function(anchor) {
  anchor.addEventListener('click', function(e) {
    var element = document.querySelector(this.getAttribute('href'));
    var offSet = element.offsetTop;
    window.scroll({ top: offSet, left: 0, behavior: 'smooth' });
    e.preventDefault();
  });
});

document.querySelectorAll('.global-form-input').forEach(function(input){
  input.addEventListener('invalid', function(e){
    var firstInvalidInput = document.querySelectorAll(":invalid")[1].id;
    if(firstInvalidInput === input.id){
      scrollToInvalid(firstInvalidInput);
    }
  });
});

function navHandler(){
  if(document.body.classList.contains('navOpen')){
    document.body.classList.remove('navOpen');
  } else {
    document.body.classList.add('navOpen');
  }
}

function navShrink() {
  var scrollDistance = 100;
  if (document.body.scrollTop > scrollDistance || document.documentElement.scrollTop > scrollDistance) {
    document.body.classList.add('headerSlimmed');
  } else {
    document.body.classList.remove('headerSlimmed');
  }
}

function randomNavBackground(){
  var w = document.documentElement.clientWidth;
  if(w > 770) {
    var random = Math.floor(Math.random() * 7) + 0;
    var img = ["url('/assets/ps-nav-bg-01.jpg')",
               "url('/assets/ps-nav-bg-02.jpg')",
               "url('/assets/ps-nav-bg-03.jpg')",
               "url('/assets/ps-nav-bg-04.jpg')",
               "url('/assets/ps-nav-bg-05.jpg')",
               "url('/assets/ps-nav-bg-06.jpg')",
               "url('/assets/ps-nav-bg-07.jpg')"];
    document.querySelector(".global-nav").style.backgroundImage=img[random];
  } else {
    document.querySelector(".global-nav").style.backgroundImage="url('/assets/ps-nav-bg-mobile.jpg')";
  }
}

function scrollToInvalid(id) {

  var navHeight = 108;
  var invalid_el = document.querySelector('#' + id + '');
  var invalid_position = invalid_el.offsetTop + navHeight;

  if ( invalid_el > (window.pageYOffset - navHeight) && invalid_el < (window.pageYOffset + window.innerHeight - navHeight) ) {
    return true;
  } else {
    window.scroll({ top: invalid_position, left: 0 });
  }

}

// --- TOASTER --- //

// Look for a message parameter
var message = getParameterByName("message");

if(message === 'failed'){
  messageToaster('', 'There has been a problem, please try again...', 'failed');
}
if(message === 'success'){
  messageToaster('', 'Your message has been sent to Philip.', 'success');
}

// Create our message timer
var messagetimer;

function messageToaster(title, message, type) {

  clearTimeout(messagetimer);

  var toaster = document.querySelector('.toaster');

  clearToaster(toaster);

  if(type === 'failed'){
    toaster.classList.add('failed');
  } else {
    toaster.classList.add('success');
  }

  document.querySelector('.toaster__content').innerText = message;
  toaster.classList.add('open');

  messagetimer = setTimeout(function() {
      toaster.classList.remove('open');
      window.history.replaceState(null, null, window.location.pathname);
  }, 8000);

}

function clearToaster(toaster) {
  toaster.classList.remove('failed', 'success');
}

// First at the URL to target correct message
function getParameterByName(name, url) {
    if (!url) {
        url = window.location.href;
    }
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

// --- TOASTER END --- //

// Reframe.js - Vanilla JS solution for responsive videos
(function (global, factory) {
  typeof exports === 'object' && typeof module !== 'undefined' ? module.exports = factory() :
  typeof define === 'function' && define.amd ? define(factory) :
  (global.reframe = factory());
}(this, (function () { 'use strict';


reframe();
// Reframe.js
// - runs for 1 child element (does not assume multiple elements)
// - does not deal with src, so it will repaint
// - it JUST creates a fluid wrapper
function Reframe(el) {
  this.frame = document.querySelector(el);
  if (!this.frame) return false;
  var frameHeight = this.frame.offsetHeight;
  var frameWidth = this.frame.offsetWidth;
  var wrapper = document.createElement('div');
  var divAdded = false;
  if (frameHeight === frameWidth) {
    wrapper.style.paddingTop = '100%';
  } else if (frameHeight > frameWidth) {
    wrapper.style.paddingTop = frameWidth / frameHeight * 100 + ' + %';
  } else {
    wrapper.style.paddingTop = frameHeight / frameWidth * 100 + ' + %';
  }
  wrapper.className += 'js-responsive-iframe';
  this.frame.removeAttribute('height');
  this.frame.removeAttribute('width');
  this.frame.removeAttribute('style');
  if (!divAdded) {
    this.frame.parentNode.insertBefore(wrapper, this.frame);
    divAdded = true;
  }
  this.frame.parentNode.removeChild(this.frame);
  wrapper.appendChild(this.frame);

  return this;
}

function reframe (el) {
  return new Reframe(el);
};

return reframe;

})));
